<template>
    <div class='payOrders'>
        <div class="planlist">
            <el-row style="margin-bottom:1rem">
                <el-col :span="24" style="text-align:left">
                    <el-input style="width:200px;" v-model="paginationInfo.condition.search_key"
                        placeholder="请输入手机号/商户单号" clearable></el-input>
                    <el-date-picker v-model="create_date[0]" align="right" type="date" placeholder="选择开始时间"
                        :picker-options="{
                            disabledDate(time) {
                                return time.getTime() > Date.now();
                            },
                        }">
                    </el-date-picker>
                    <el-date-picker v-model="create_date[1]" align="right" type="date" placeholder="选择结束时间"
                        :picker-options="{
                            disabledDate(time) {
                                return time.getTime() > Date.now();
                            },
                        }">
                    </el-date-picker>
                    <el-select v-model="paginationInfo.condition.trade_status" placeholder="请选择订单状态" @change="queryList"
                        filterable clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="支付成功" value="SUCCESS"></el-option>
                        <el-option label="转入退款" value="REFUND"></el-option>
                        <el-option label="预创建" value="PRE_CREATE"></el-option>
                        <el-option label="未支付" value="NOTPAY"></el-option>
                        <el-option label="已关闭" value="CLOSED"></el-option>
                        <el-option label="已撤销（仅付款码支付会返回）" value="REVOKED"></el-option>
                        <el-option label="用户支付中（仅付款码支付会返回）" value="USERPAYING"></el-option>
                        <el-option label="支付失败（仅付款码支付会返回）" value="PAYERROR"></el-option>
                    </el-select>
                    <el-select v-model="paginationInfo.condition.biz_type" placeholder="请选择订单类型" @change="queryList"
                        filterable clearable>
                        <el-option label="全部订单类型" value=""></el-option>
                        <el-option v-for="item in orderDoctData" :key="item.dictdata_value" :label="item.dictdata_name"
                            :value="item.dictdata_value"></el-option>
                    </el-select>
                    <el-button @click="queryList">查询</el-button>
                </el-col>
            </el-row>
            <el-table :data="tableData" style="width: 100%">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-form>
                            <el-form-item label="IP:">
                                <span>{{ props.row.ip }}</span>
                            </el-form-item>
                            <el-form-item label="user_agent:">
                                <span>{{ props.row.http_user_agent }}</span>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column prop="out_trade_no" label="订单号" align='center' width="150">
                </el-table-column>
                <el-table-column label="创建时间" align='center' width="300">
                    <template slot-scope="scope">{{ $moment(scope.row.create_date).format('YYYY-MM-DD HH:mm:ss')
                        }}</template>
                </el-table-column>
                <el-table-column prop="subject" label="subject" align='center'>
                </el-table-column>
                <el-table-column label="订单类型" align='center'>
                    <template slot-scope="scope">
                        <span v-if="scope.row.biz_type == 'U_SHARE_MEMBER'">优享会员</span>
                        <span v-else-if="scope.row.biz_type == 'MEITUAN_HONGBAO'">美团红包</span>
                        <span v-else-if="scope.row.biz_type == 'EASY_ORDER'">商品订单</span>
                        <span v-else-if="scope.row.biz_type == 'EQUITY_MEMBER'">权益会员</span>
                        <span v-else>无</span>
                    </template>
                </el-table-column>
                <el-table-column label="支付价格" align='center'>
                    <template slot-scope="scope">{{ scope.row.total_amount / 100 }}元</template>
                </el-table-column>
                <el-table-column label="支付账号" align='center'>
                    <template slot-scope="scope">
                        <span v-if="scope.row.account_channel == 'languagemember'">language会员</span>
                        <span v-else-if="scope.row.account_channel == 'blkjwechatpay'">冰蓝科技</span>
                        <span v-else-if="scope.row.account_channel == 'tllgwechatpay'">陀螺领购</span>
                        <span v-else>无</span>
                    </template>
                </el-table-column>
                <el-table-column label="渠道" align='center'>
                    <template slot-scope="scope">
                        {{ getDictName(scope.row.channel_code) }}
                    </template>
                </el-table-column>
                <el-table-column label="订单状态" align='center'>
                    <template slot-scope="scope">
                        <span v-if="scope.row.trade_status == 'SUCCESS'">支付成功</span>
                        <span v-else-if="scope.row.trade_status == 'REFUND'">转入退款</span>
                        <span v-else-if="scope.row.trade_status == 'PRE_CREATE'">预创建</span>
                        <span v-else-if="scope.row.trade_status == 'NOTPAY'">未支付</span>
                        <span v-else-if="scope.row.trade_status == 'CLOSED'">已关闭</span>
                        <span v-else-if="scope.row.trade_status == 'REVOKED'">已撤销（仅付款码支付会返回）</span>
                        <span v-else-if="scope.row.trade_status == 'USERPAYING'">用户支付中（仅付款码支付会返回）</span>
                        <span v-else-if="scope.row.trade_status == 'PAYERROR'">支付失败（仅付款码支付会返回）</span>
                        <el-button type="primary" @click="ordersSynctrade(scope.row._id)"
                            plain>同步</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="paginationInfo.pageNo" :page-sizes="[10, 20, 50, 100]"
                :page-size="paginationInfo.pageSize" :total="paginationInfo.total"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            orderDoctData: [],
            dictData: [],
            create_date: [],
            paginationInfo: {
                pageSize: 10,
                total: 0,
                pageNo: 1,
                condition: {
                    out_trade_no: '',
                    create_date: '',
                    trade_status: '',
                    biz_type: '',
                }
            },
            tableData: [],
            formData: {
            },
        }
    },
    computed: {},
    watch: {},
    created() {
        this.getManagerTrades()
        this.getDict();
        this.getOrderDoctData();
    },
    mounted() {
    },
    methods: {
        ordersSynctrade(id) {
            this.$confirm('是否同步此条数据?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$service.OnlineOrderService.wechatpayOrdersSynctrade({ id }).then(res => {
                    if (res.data.code === 0) {
                        this.getManagerTrades();
                        this.$message({
                            message: '同步成功！',
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.data.message,
                            type: 'error'
                        });
                    }
                }).catch((err) => {
                    window.console.log(err)
                    this.$message({
                        message: '网络错误，请重新修改！',
                        type: 'error'
                    });
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        getDictName(str) {
            let name = "无";
            this.dictData.forEach(item => {
                if (item.dictdata_name == str) {
                    name = item.dictdata_value;
                }
            });
            return name;
        },
        getDict() {
            this.$service.CommonService
                .getDict({ dict: 'AdChannelInfos' })
                .then(res => {
                    if (res.data.code === 0) {
                        this.dictData = res.data.data.dict_data;
                    }
                })
        },
        getOrderDoctData() {
            this.$service.CommonService
                .getDict({ dict: 'PayBizTypes' })
                .then(res => {
                    if (res.data.code === 0) {
                        this.orderDoctData = res.data.data.dict_data;
                    }
                })
        },
        queryList() {
            if (this.create_date.length >= 2 && this.create_date[0] && this.create_date[1]) {
                this.paginationInfo.condition.create_date = [this.create_date[0], this.create_date[1]];
            } else {
                this.paginationInfo.condition.create_date = null;
            }
            this.paginationInfo.pageNo = 1;
            this.getManagerTrades()
        },
        handleSizeChange(val) {
            window.console.log(`每页 ${val} 条`);
            this.paginationInfo.pageSize = val;
            this.paginationInfo.pageNo = 1;
            this.getManagerTrades();
        },
        handleCurrentChange(val) {
            window.console.log(`当前页: ${val}`);
            this.paginationInfo.pageNo = val;
            this.getManagerTrades();
        },
        getManagerTrades() {
            this.$service.OnlineOrderService
                .getManagerTrades(this.paginationInfo)
                .then(res => {
                    if (res.data.code === 0) {
                        let dataInfo = res.data.data
                        this.paginationInfo.total = dataInfo.total;
                        this.tableData = dataInfo.trades;
                    }
                })
        },
    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能,这个函数会触发
}
</script>
<style lang='less' scoped>
.payOrders {
    ::v-deep .el-form-item__label {
        font-weight: bold;
    }
}
</style>